import React from "react"

class Redirect extends React.Component {

    constructor(props){
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        const request_origin = urlParams.get('state');
        fetch("https://api.ifficient.tech/v1/configs/" + request_origin, {method: 'GET'})
        .then(response => response.json()).then(data => {
            if(data.status === 'success' && 'domain' in data.data)
                window.location.replace(data.data.domain + '/redirect?code=' + code);
            else
                window.location.replace('https://www.ifficient.tech');
        })
    }

    render() {
        return (<></>)
    }
}

export default Redirect