import React from "react"
import "./startpage.css";

class StartPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            phase: 1,
            sitename: '',
            email: '',
            username: '',
            password: '',
            number: '',
            code: '',
            url: '',
            error: '',
        };
        this.handleSubmit1 = this.handleSubmit1.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setError = this.setError.bind(this);
    }

    setError(e){
        this.setState({error: e});
        setTimeout(()=>{this.setState({error: null});}, 3000);
    }

    handleSubmit1(e){
        e.preventDefault();
        fetch("https://api.ifficient.tech/v1/configs/" + this.state.sitename, {method: 'GET'})
        .then(response => response.json()).then(data => {
            if(data.status === 'fail'){ // If the config wasn't found
                fetch("https://api.ifficient.tech/v1/twofactor/" + this.state.sitename,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        phone: this.state.number
                    })
                })
                .then(response => response.json()).then(data => {
                    if(data.status === 'success')
                        this.setState({phase: 2});
                    if(data.status === 'fail') {
                        this.setState({number:'+'});
                        this.setError('Unable to send SMS to the provided number');
                    }
                    if(data.status === 'error')
                        this.setError('Something went wrong on our side, try again some other time');
                })
            }
            if (data.status === 'error') // The sitename already exists
                this.setError('Something went wrong on our side, try again later');
            if (data.status === 'success'){
                this.setState({sitename:''});
                this.setError('That sitename was already taken, try again');
            }
        });
    }

    handleSubmit2(e){
        e.preventDefault();
        if(this.state.code.length !== 6)
            this.setState({error: 'Please enter all six digits'})
        fetch("https://api.ifficient.tech/v1/deploy/" + this.state.sitename,
            {
                method: 'POST',
                body: JSON.stringify({
                    sms_code: this.state.code,
                    sitename: this.state.sitename,
                    username: this.state.username,
                    password: this.state.password,
                    email: this.state.email,
                    phone: this.state.number
                })
            })
            .then(response => response.json()).then(data => {
            if(data.status === 'success')
                this.setState({phase: 3, url: data['url']});
            if(data.status === 'fail'){
                this.setState({code:''});
                this.setError('Incorrect or expired code');
            }
            if(data.status === 'error')
                this.setError('Something went wrong on our side, try again some other time');
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        if(name === 'number'){
            var v = value.replace(/\D/g,'');
            var e = v.length;
            if(v.length > 10){
                v = v.slice(0, e-10)+' ('+v.slice(e-10, e-7)+') '+v.slice(e-7,e-4)+'-'+v.slice(e-4, e)
            }
            value = '+' + v;
        }
        this.setState({
            [name]: value
        });
      }

    render() {
        return (
            <div id="main">
            {this.state.phase === 1 ? // Information submitting phase
                    <form className='form'>
                        <h1>Build Your Community the Right Way</h1>
                        <p className="has-dynamic-label">
                            <input type="text" name='sitename' id="sitename" placeholder="One Word Sitename*" value={this.state.sitename} onChange={this.handleInputChange} required pattern="^[A-Za-z0-9]+$" minlength='4' maxlength='25'/>
                            <label for="sitename">One word sitename*</label>
                        </p>
                        <p className="has-dynamic-label">
                            <input type="text" name='email' id="email" placeholder="Email*" value={this.state.email} onChange={this.handleInputChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                            <label for="email">Email*</label>
                        </p>
                        <p className="has-dynamic-label">
                            <input type="text" name='username' id="username" placeholder="Admin Username*" value={this.state.username} onChange={this.handleInputChange} required />
                            <label for="username">Admin Username*</label>
                        </p>
                        <p className="has-dynamic-label">
                            <input type="password" name='password' id="password" placeholder="Admin Password*" value={this.state.password} onChange={this.handleInputChange} minlength='6' required />
                            <label for="password">Admin Password*</label>
                        </p>
                        <p className="has-dynamic-label">
                            <input type="text" name='number' id="number" placeholder="Phone Number*" value={this.state.number} onChange={this.handleInputChange} minlength='17' required />
                            <label for="number">Phone Number with country code*</label>
                        </p>
                        <button onClick={this.handleSubmit1} className="submit">Get Started</button>
                    </form>
            : null}
            {this.state.phase === 2 ? // Phone verification phase
                    <form className='form'>
                        <h1>One Final Step</h1>
                        <h3>Enter the verification code sent to your phone</h3>
                        <h5>Only verified beta testers can currently register</h5>
                        <p className="has-dynamic-label">
                            <input type="text" name='code' id="code" placeholder="SMS Verification Code" value={this.state.code} onChange={this.handleInputChange} pattern='[0-9]{6}$' maxlength='6' required />
                            <label for="code">SMS Verification Code</label>
                        </p>
                        <button onClick={this.handleSubmit2} className="submit">Verify</button>
                    </form>
            :null}
            {this.state.phase === 3 ? // Display the link
                    <div>
                        <p>Your site is being setup right now</p>
                    </div>
            :null}
            {this.state.error ?
                <div id='toast-box'>
                    <p id='toast-message'>{this.state.error}</p>
                </div>
            : null}
            </div>
        )
    }
}

export default StartPage