import React from "react"
import "./homepage.css";
import example from "./example.png";
import pricing from "./pricing.svg";

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.features = [
            ["Multi Play Homepage", "The homepage shows every play you\"ve made: options, watchlists, and trades"],
            ["Community Tab", "You post and your community can interact just like on YouTube"],
            ["Custom Pages", "Show off your courses on a page of their own and add as many pages as you want"],
            ["Fully Configurable", "Make the website your own with configurable logos, colors, and markdown on every page"],
            ["Frontend/Backend Hosting", "Just click get started and you can have a dedicated site within 1 day"],
            ["Membership System", "A flexible rank system allows for different perks for different subscriptions"],
            ["Play Data", "Each play is displayed with prices, charts, analysis, and p/l percentages"],
            ["Live Chats", "Chats for each play, chat\"s for each community post, and general chats"],
            ["Mobile Ready", "Your site will work flawlessly on mobile helping to attract all demographics"],
            ["Custom Domain Support", "Contact customer service to put your site on any domain you own"],
            ["Discord Bot", "Whenever you post a play, or a user buys a rank the bot will mirror those changes in your server"],
            ["Optional Auditing", "Don\"t edit plays after posting and you can opt to display our audit seal"],
        ];
        this.animate = this.animate.bind(this);
    }

    animate() {
        var elems = document.querySelectorAll("section");
        for (var i = 0; i < elems.length; i++) {
            elems[i].classList.toggle("section-anim");
        }
    }

    render() {
        return (
            <div id="main">

                <section id="tagline">
                    Build your following.<br />
                    Publish your insights.<br />
                    Monetize your success.<br />
                </section>

                <section id="example">
                    <div id="example-explanation" className="card">
                        <h1 className="fancy-header">Springloading</h1>
                        <p className="normal-text">
                            Setup using ifficient in under 10 minutes to replace Discord for a 20,000 member
                            community<br /><br />Click the site to take a look</p>
                    </div>
                    <a href='https://demo.ifficient.tech'>
                        <img onLoad={this.animate} alt="Example ifficient site" id="example-site" src={example} title="Springloading example site"></img>
                    </a>
                </section>

                <section id="about">
                    <div className="panel">
                        <h1 className="section-header">About</h1>
                        <div className="card">
                            <p className="normal-text">
                                &emsp; Building a strong community around signals, plays, or courses has always relied on complex Discord servers
                                with hundreds of channels.<br /><br />
                                &emsp; Now, with groupchats, charts, and data embedded in each published play, Discord integration, a community
                                tab, push notifications, ability to sell courses and bots, and levelled paywalling; ifficient makes it possible for playmakers to build
                                a vibrant, organized community while looking more professional than ever.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <p className="definition-word">PLAY</p>
                            <p className="normal-text definition-pron">: noun - [plā]</p>
                            <p className="normal-text">An action taken in the stock, forex, or cryptocurrency markets i.e.
                                buying a call option, making a watchlist, or selling shares</p>
                        </div>
                        <div>
                            <p className="definition-word">PLAYMAKER</p>
                            <p className="normal-text definition-pron">: noun - [ˈplāˌmākər]</p>
                            <p className="normal-text">A person knowledgable in the markets who makes their plays public
                                i.e. Warren Buffett</p>
                        </div>
                        <div>
                            <p className="definition-word">SIGNAL</p>
                            <p className="normal-text definition-pron">: noun - [ˈsiɡnəl]</p>
                            <p className="normal-text">An alert created by a playmaker to join in on a play
                                i.e. an alert to buy shares</p>
                        </div>
                    </div>
                </section>

                <section id="features">
                    <div id="features-header">
                        <h1 className="section-header">Features</h1>
                    </div>
                    {this.features.map(feature => {
                        return (
                            <div key={feature[0]} className="card small-margin">
                                <h1 className="normal-text">{feature[0]}</h1>
                                <p className="normal-text">{feature[1]}</p>
                            </div>
                        )
                    })}
                </section>

                <section id="pricing" className="section-flip">
                    <div className="panel">
                        <p className="section-header">Pricing</p>
                        <div className="card">
                            <p className="normal-text">
                                &emsp; ifficient only makes money when you make money. When you begin to make revenue, ifficient takes 3% but
                                is completely free otherwise! That"s less than sales tax!</p>
                        </div>
                    </div>
                    <img id="pricing-img" src={pricing} alt="How the revenue is split"></img>
                </section>
                <div id="footer">
                    <p className="main-title">IFFICIENT</p>
                    <p id="disclaimer">Disclaimer: ifficient is not liable for any actions or information made available by ifficient clients
                        through ifficient software.</p>
                </div>
            </div>
        )
    }
}

export default HomePage