
import React from "react"
import { Switch, Route } from "react-router-dom";
import "./App.css"

import { AppBar, HomePage, StartPage, Redirect } from "./components";

function App() {

  return (
    <div>
      <header>
      <Switch>
          <Route exact path="/redirect" component={null} />
          <Route component={AppBar} />
        </Switch>
      </header>

      <main>
        <Switch>
          <Route exact path="/create" component={StartPage} />
          <Route exact path="/redirect" component={Redirect} />
          <Route component={HomePage} />
        </Switch>
      </main>
    </div>
  );
}

export default App