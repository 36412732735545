import React from "react"

import "./appbar.css";

class AppBar extends React.Component {

    constructor(props) {
        super(props);
        this.smoothScroll = this.smoothScroll.bind(this);
    }

    render() {
        return (
            <div id="bar">
                <button onClick={() => this.smoothScroll("#bar")} className="main-title">IFFICIENT</button>
                <button className="action collapse" onClick={() => window.location.href = "/create"}>Get Started</button>
                <ul id="nav-list">
                    <li className="nav-item"><button onClick={() => this.smoothScroll("#about")}>About</button></li>
                    <li className="nav-item"><button onClick={() => this.smoothScroll("#features")}>Features</button></li>
                    <li className="nav-item"><button onClick={() => this.smoothScroll("#pricing")}>Pricing</button></li>
                </ul>
                {window.location.pathname !== "/create" ? 
                <button className="action expand" onClick={() => window.location.href = "/create"}>Get Started</button>
                : null}
            </div>
        )
    }

    smoothScroll(selector) {
        if (window.location.pathname === "/create") {
            window.location.href = "/" + (selector === "#bar" ? "" : selector);
        }
        document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
        });
    }
}

export default AppBar